import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text, Span } from "../../../components/Core";
import { device } from "../../../utils";



const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;










const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const BAcnffeCauddddsesPage = () => (
  <>
    {/* <!-- Content section 1 --> */}
  
    <Section bg="#ffffff" py={4}>
        <Container className="pt-3 pb-3">
          <Row className="pb-4 align-items-center">
            <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
              <div
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
          <Iwrap>

          
          <Itext as="h2">Excess Sweating <span>London</span></Itext> 
          </Iwrap>
                <SecondText>
                Causes of Excess Sweating
                </SecondText>

               
           
              </div>
            </div>
          
          </Col>

         
        </Row>

        <Row className="align-items-center">
            <Col lg="12">
            <Text>
            Excessive sweating, also known as hyperhidrosis, can be caused by a variety of factors, including:
            <br /> <br />
 

Overactive sweat glands: Hyperhidrosis is sometimes caused by overactive sweat glands, which produce more sweat than the body requires.
<br /> <br />
 

Medical conditions: Diabetes, hyperthyroidism, menopause, and nervous system disorders such as Parkinson's disease or multiple sclerosis are all examples of medical conditions that can induce hyperhidrosis.
<br /> <br />
 

Medications: As a side effect, some drugs, such as antidepressants and blood pressure medications, can produce excessive perspiration.
<br /> <br />
 

Diseases: Certain bacterial or viral illnesses, such as tuberculosis, HIV, and endocarditis, can produce excessive perspiration.
<br /> <br />
 

Genetics: Hyperhidrosis can run in families, implying that the disorder has a hereditary component.
<br /> <br />
 

Psychological factors: For certain people, emotional stress and anxiety can cause excessive perspiration.
<br /> <br />
 

Menopause: Hot flashes and profuse perspiration can be caused by hormonal changes during menopause.
<br /> <br />
 

Obesity: Being overweight increases your chances of having hyperhidrosis.
<br /> <br />
               </Text>
            </Col>

        </Row>

<Row>
  <Col>
  <Text>
              Excessive sweating can cause the following symptoms:
              <br /> <br />
 

              → Visible sweating, even when not engaged in physical activity or in a cool setting 
<br /> <br />
→ Skin that feels wet or damp often
<br /> <br />
→ Increased body odour 
<br /> <br />
→ Skin irritation caused by prolonged dampness, such as rashes or fungal infections
<br /> <br />
→ Difficulty gripping objects due to increased perspiration of the hands

<br /> <br />
</Text>
  </Col>
</Row>
       
   
      </Container>

    
     
    </Section>

    
  </>
);

export default BAcnffeCauddddsesPage;
